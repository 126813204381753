@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.container {
    padding-top: 2rem;
    padding-bottom: 100px;
}

.sectionSpacing {
    margin-bottom: 50px;
}

.bottomContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    background-color: color('white');
    box-shadow: 0px -3px 6px #D0DDE680;
}

.bottomInnerContainer {
    @include containerWrapper;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 0;
}
.floatingDesignation {
    border-radius: 1.5rem;
    background-color: color('lightGrayishBlue');
    padding: 0.5rem 1rem;
    box-shadow:0px 3px 6px #00000029;
    position: fixed;
}
.fadeInDesignation {
    animation: fadeIn 4s;
    -webkit-animation: fadeIn 4s;
    -moz-animation: fadeIn 4s;
    -o-animation: fadeIn 4s;
    -ms-animation: fadeIn 4s;
}
@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

