@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.label {
    display: block;
    @include fontWeight('light');
    font-size: 14px;
    margin-bottom: 2px;
}

.requiredField{
    color: color('danger');
}