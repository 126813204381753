/*CUSTOM CSS's*/
.ReactTable {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;

	* {
		box-sizing: border-box;
	}
	&.-striped {
		.rt-tr.-odd {
			background: rgba(92, 92, 92, 0.03);
		}
	}
	&.-highlight {
		.rt-tbody {
			.rt-tr {
				&:not(.-padRow) {
					&:hover {
						background: rgba(92, 92, 92, 0.05);
					}
				}
			}
		}
	}

	box-shadow: -4px 5px 7px 0px rgb(232 232 232 / 89%);

	.rt-table {
		-webkit-box-flex: 1;
		-ms-flex: auto 1;
		flex: auto 1;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		width: 100%;
		border-collapse: collapse;
		overflow-x: auto;
		overflow-y: clip;

		@include scrollbars();
	}
	.rt-thead {
		-webkit-box-flex: 1;
		-ms-flex: 1 0 auto;
		flex: 1 0 auto;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		.rt-th.-cursor-pointer {
			cursor: pointer;
		}
		.rt-td.-cursor-pointer {
			cursor: pointer;
		}
		.rt-th {
			&:last-child {
				border-right: 0;
			}
			&:focus {
				outline-width: 0;
			}
			padding: 7px 5px;
			line-height: normal;
			position: relative;
			transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
			box-shadow: inset 0 0 0 0 transparent;
		}
		.rt-td {
			&:last-child {
				border-right: 0;				
			}
			padding: 5px 5px;
			line-height: normal;
			position: relative;
			transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
			box-shadow: inset 0 0 0 0 transparent;
		}
		.rt-resizable-header {
			overflow: visible;
			&:last-child {
				overflow: hidden;
scrollbar-width: none;
			}
		}
		.rt-resizable-header-content {
			overflow: hidden;
scrollbar-width: none;
			text-overflow: ellipsis;
			cursor: default;
		}
		.rt-header-pivot {
			border-right-color: #f7f7f7;
			&:after {
				left: 100%;
				top: 50%;
				border: solid transparent;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-color: rgba(255, 255, 255, 0);
				border-left-color: #fff;
				border-width: 8px;
				margin-top: -8px;
			}
			&:before {
				left: 100%;
				top: 50%;
				border: solid transparent;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-color: rgba(102, 102, 102, 0);
				border-left-color: #f7f7f7;
				border-width: 10px;
				margin-top: -10px;
			}
		}
		.rt-tr {
			text-align: left;
			align-items: center;
			.rt-resizable-header-content {
				text-transform: uppercase;
			}
		}
	}
	.rt-thead.-headerGroups {
		background: rgba(92, 92, 92, 0.03);
		border-bottom: 1px solid rgba(92, 92, 92, 0.05);
	}
	.rt-thead.-filters {
		border-bottom: 1px solid rgba(92, 92, 92, 0.05);
		.rt-th {
			border-right: 1px solid rgba(92, 92, 92, 0.02);
			position: inherit;

			&.rthfc-th-fixed-left {
				position: sticky !important;
			}
			&.rthfc-th-fixed-right {
				position: sticky !important;
			}

			font-size: 13px;
		}
		.rt-tr {
			background-color: white;
		}
		input {
			padding: 0.6rem 1rem;
			border-radius: none !important;
			font-size: 13px;
		}
		select {
			padding: 0.6rem 1rem;
			border-radius: none !important;
			font-size: 13px;
		}
		input[type="number"] {
			padding: 0.65rem 1rem !important;
			border-radius: none !important;
			font-size: 13px;
		}
	}
	.rt-tbody {
		-webkit-box-flex: 99999;
		-ms-flex: 99999 1 auto;
		flex: 99999 1 auto;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		//overflow: auto;
		.rt-tr-group {
			background-color: white;
			&:last-child {
				border-bottom: 0;
				border-left: 0;
			}
		}
		.rt-td {
			border-right: 1px solid rgba(92, 92, 92, 0.02);
			border-right: 0px solid rgba(92, 92, 92, 0.02);
			&:last-child {
				border-right: 0;
				border-left: 0;
			}
			font-size: 14px;
		}
		.rt-expandable {
			cursor: pointer;
			text-overflow: clip;
		}
	}
	.rt-tr-group {
		-webkit-box-flex: 1;
		-ms-flex: 1 0 auto;
		flex: 1 0 auto;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}
	.rt-tr {
		-webkit-box-flex: 1;
		-ms-flex: 1 0 auto;
		flex: 1 0 auto;
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
		cursor: pointer;
	}
	.rt-th {
		-webkit-box-flex: 1;
		-ms-flex: 1 0 0px;
		flex: 1 0 0;
		white-space: nowrap;
		text-overflow: ellipsis;
		padding: 7px 5px;
		overflow: hidden;
scrollbar-width: none;
		transition: 0.3s ease;
		transition-property: width, min-width, padding, opacity;
	}
	.rt-td {
		-webkit-box-flex: 1;
		-ms-flex: 1 0 0px;
		flex: 1 0 0;
		white-space: nowrap;
		text-overflow: ellipsis;
		padding: 7px 5px;
		overflow: hidden;
scrollbar-width: none;
		transition: 0.3s ease;
		transition-property: width, min-width, padding, opacity;
		border-bottom: solid 1px color("mercury");
	}
	.rt-th.-hidden {
		width: 0 !important;
		min-width: 0 !important;
		padding: 0 !important;
		border: 0 !important;
		opacity: 0 !important;
	}
	.rt-td.-hidden {
		width: 0 !important;
		min-width: 0 !important;
		padding: 0 !important;
		border: 0 !important;
		opacity: 0 !important;
	}
	.rt-expander {
		display: inline-block;
		position: relative;
		margin: 0;
		color: transparent;
		margin: 0 10px;
		&:after {
			content: "";
			position: absolute;
			width: 0;
			height: 0;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%, -50%) rotate(-90deg);
			transform: translate(-50%, -50%) rotate(-90deg);
			border-left: 5.04px solid transparent;
			border-right: 5.04px solid transparent;
			border-top: 7px solid rgba(92, 92, 92, 0.8);
			transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
			cursor: pointer;
		}
	}
	.rt-expander.-open {
		&:after {
			-webkit-transform: translate(-50%, -50%) rotate(0);
			transform: translate(-50%, -50%) rotate(0);
		}
	}
	.rt-resizer {
		display: inline-block;
		position: absolute;
		width: 36px;
		top: 0;
		bottom: 0;
		right: -18px;
		cursor: col-resize;
		z-index: 10;
	}
	.rt-tfoot {
		-webkit-box-flex: 1;
		-ms-flex: 1 0 auto;
		flex: 1 0 auto;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		box-shadow: 0 0 15px 0 rgba(92, 92, 92, 0.15);
		.rt-td {
			border-right: 1px solid rgba(92, 92, 92, 0.05);
			&:last-child {
				border-right: 0;
			}
		}
	}
	.-pagination {
		z-index: 1;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		padding: 3px;
		box-shadow: 0 0 15px 0 rgba(92, 92, 92, 0.1);
		border-top: 2px solid rgba(92, 92, 92, 0.1);
		input {
			border: 1px solid rgba(92, 92, 92, 0.1);
			background: #fff;
			padding: 5px 7px;
			font-size: inherit;
			border-radius: 3px;
			font-weight: normal;
			outline-width: 0;
		}
		select {
			border: 1px solid rgba(92, 92, 92, 0.1);
			background: #fff;
			padding: 5px 7px;
			font-size: inherit;
			border-radius: 3px;
			font-weight: normal;
			outline-width: 0;
		}
		.-btn {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			display: block;
			width: 100%;
			height: 100%;
			border: 0;
			border-radius: 3px;
			padding: 6px;
			font-size: 1em;
			color: rgba(92, 92, 92, 0.6);
			background: rgba(92, 92, 92, 0.1);
			transition: all 0.1s ease;
			cursor: pointer;
			outline-width: 0;
			&:not([disabled]) {
				&:hover {
					background: rgba(92, 92, 92, 0.3);
					color: #fff;
				}
			}
		}
		.-btn[disabled] {
			opacity: 0.5;
			cursor: default;
		}
		.-previous {
			-webkit-box-flex: 1;
			-ms-flex: 1;
			flex: 1;
			text-align: center;
		}
		.-next {
			-webkit-box-flex: 1;
			-ms-flex: 1;
			flex: 1;
			text-align: center;
		}
		.-center {
			-webkit-box-flex: 1.5;
			-ms-flex: 1.5;
			flex: 1.5;
			text-align: center;
			margin-bottom: 0;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-orient: horizontal;
			-webkit-box-direction: normal;
			-ms-flex-direction: row;
			flex-direction: row;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			-ms-flex-pack: distribute;
			justify-content: space-around;
		}
		.-pageInfo {
			display: inline-block;
			margin: 3px 10px;
			white-space: nowrap;
		}
		.-pageJump {
			display: inline-block;
			input {
				width: 70px;
				text-align: center;
			}
		}
		.-pageSizeOptions {
			margin: 3px 10px;
		}
	}
	.rt-noData {
		display: block;
		position: absolute;
		left: 50%;
		top: 70%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		background: rgba(255, 255, 255, 0.9);
		transition: all 0.3s ease;
		z-index: 1;
		pointer-events: none;
		padding: 20px;
		color: rgba(92, 92, 92, 0.75);
		border: 1px solid color("mercury");
	}
	.-loading {
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: rgba(255, 255, 255, 0.8);
		transition: all 0.3s ease;
		z-index: -1;
		opacity: 0;
		pointer-events: none;
		> div {
			position: absolute;
			display: block;
			text-align: center;
			width: 100%;
			top: 50%;
			left: 0;
			font-size: 15px;
			color: rgba(92, 92, 92, 0.6);
			-webkit-transform: translateY(-52%);
			transform: translateY(-52%);
			transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
		}
	}
	.-loading.-active {
		opacity: 1;
		z-index: 2;
		pointer-events: all;
		> div {
			-webkit-transform: translateY(50%);
			transform: translateY(50%);
		}
	}
	.rt-resizing {
		.rt-th {
			transition: none !important;
			cursor: col-resize;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		.rt-td {
			transition: none !important;
			cursor: col-resize;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
	}
	.rt-thead.-header {
		box-shadow: unset;
	}

	::-webkit-input-placeholder {
		text-transform: capitalize !important;
	}

	:-moz-placeholder {
		text-transform: capitalize !important;
	}

	::-moz-placeholder {
		text-transform: capitalize !important;
	}

	:-ms-input-placeholder {
		text-transform: capitalize !important;
	}
}
/*CUSTOMIZED*/
.rthfc {
	.rt-thead {
		&.-headerGroups {
			z-index: 0 !important;
		}
		&.-header {
			z-index: 0 !important;
		}
	}
	.-header {
		.rt-th {
			background-color: color("athensGray");
			color: color("gray") ;
		}
	}
	.-filters {
		z-index: 0 !important;

		.rt-th.rthfc-th-fixed-left-last {
			border-right: none !important;
		}
		.rt-th.rthfc-th-fixed-right-first {
			border-left: none !important;
		}
	}
	.rt-th.rthfc-th-fixed-left-last {
		border-right: none !important;
	}
	.rt-td.rthfc-td-fixed-left-last {
		//box-shadow: 7px 10px 20px -10px color('gray');
		box-shadow: 7px -1px 1px -10px rgb(232, 232, 232, 0.62), 4px -1px 3px 0px rgb(232, 232, 232, 0.62);
	}
	.rt-th.rthfc-th-fixed-right-first {
		border-left: none !important;
	}

	.rt-th.rthfc-th-fixed.rthfc-th-fixed-right {
		text-align: center;
	}
	.rt-td.rthfc-td-fixed.rthfc-td-fixed-right {
		text-align: center;
		overflow: initial;
	}
	.rt-td.rthfc-td-fixed.rthfc-td-fixed-left.rthfc-td-fixed-left-last {
		border-right: solid 1px color("mercury");
	}

	&.-sp {
		.rthfc-th-fixed {
			position: sticky;
			z-index: 1;
		}
		.rthfc-td-fixed {
			position: sticky;
			z-index: 1;
		}
	}

	.rt-tfoot{
		.rt-tr{
			.rt-td{
				border-bottom: none;
			}
		}
	}
}

.react-datepicker-popper { 
	z-index: 9 !important 
}

.ReactModalPortal{
    .ReactModal__Overlay{
        z-index: 151 !important;
    }
}