@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.formContent {
    width: 100%;
}

.formItem {
    padding: 0.25rem 0.5rem
}

.bold {
    @include fontWeight("bold");
}

.buttonsFooter {
    text-align: right;
    padding: 1rem
}