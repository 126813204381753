@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.icon{    
    color:color('primary');
    cursor: pointer;
}

.dropdownContainer {
    position: absolute;
    margin-top: 5px;
    background-color: color('white');
    border-radius: 4px;
    border: 1px solid color('athensGray');
    display: flex;
    flex-direction: column;
    justify-content: center;
    word-break: keep-all;   
    z-index: 10; 
}

.toTop{
    top: 0;
}

.toBottom{
    bottom: 0;
}

.dropdownItem {
    font-size: 14px;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    outline: 0;
    padding: 14px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: color('lightblack');
    

    &:hover {
        background-color: darken(color('white'), 3.5);
        border: 0.5px solid color('gray');
        border-radius: 2px;
    }
}
