@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.paginationContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1.5rem;

    .paginationInfo {
        flex: 1;
        font-size: 14px;
        color: color('gray');
    }
}

.filterContainer{
    display: flex;
    flex: 1;
    justify-content: flex-end;

}

.btnFilters{
    border: 1px solid color("primary") !important;
    margin-right: 0.5rem;
}

.counter {
    border-radius: 50%;
    background-color: color("primary");
    color: color("white");
    font-size: 0.65rem;
    display: inline-block;
    flex-direction: row;
    width: 15px;
    height: 15px;
    position: absolute;
    mix-blend-mode: darken;
    margin-left: 5px;
    margin-top: 1px;
}

.counterNumber {
    padding-top: 2px;
}
