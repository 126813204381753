@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.groups {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.groupItem {
    background: color('athensGray');
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 10px;
    padding: 12px;
    color: color('lightblack');
    cursor: pointer;

    .groupItemText {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .groupItemLabelText {
        font-size: 14px;
    }

    .groupItemLabelTextSingle {
        padding-top: 2px;
    }

    .groupItemSubLabelText {
        font-size: 10px;
    }

    &.active {
        background-color: color('primary');
        color: color('white');
    }

}

.children {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: color('athensGray');
    padding: 12px;
    flex-wrap: wrap;
}

.childrenItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
    padding: 0.5em;
}

.itemDesactive {
    background-color: color('melon');
}