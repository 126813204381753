@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";


.multiselect {
    color: color(primary);
    white-space: nowrap;
    overflow: hidden;
scrollbar-width: none;
    text-overflow: ellipsis;
}

.multiText {
    padding: 0.5rem 1rem  !important;
    border-radius: 50% !important;
}

.label{
    color: color(primary);
    white-space: nowrap;
    overflow: hidden;
    scrollbar-width: none;
    text-overflow: ellipsis;
    font-size: medium;
}

.subLabel{
    color: color(primary);
    white-space: nowrap;
    overflow: hidden;
    scrollbar-width: none;
    text-overflow: ellipsis;
    font-size: small;
}