@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";

.modalContainer{
    width: 100%;
    position: relative;
}

.titleContainer {
    border-bottom: 1px solid color('mercury');
}

.bold {
    @include fontWeight('bold');
}

.contentModal{
    padding-top: 1rem;
    max-height: 33rem;
    overflow-y: scroll;
    @include scrollbars();
}

.footerModal{
    border-top: 1px solid color('mercury');
    padding: 0.5rem 1.75rem;
}

.textTitle {
    font-size: 1.25rem;
    font-weight: 'bold';
    @include fontWeight('bold');
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: color('primary');
}

.buttons_container {
    margin: 1rem 0;
    text-align: right;
};

.paddingDefault{
    padding: 0.5rem 1.75rem;
}

.noMarginHorizontal{
    margin-right:0;
    margin-left: 0;
}